import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import MC from '../assets/img/Michael-Coleman.webp'
import AJ from '../assets/img/Albert-Johnson.webp'
import DM from '../assets/img/Dennis-Coleman.webp'
import LH from '../assets/img/Lauren-Haughton.webp'

import logoWhite from '../assets/img/logo-white.png'

const MeetTheTeam = () => {
    const navigate = useNavigate();

    const [animationNavDesktop, setAnimationNavDesktop] = useState('move-3');
    const [animationNavHome, setAnimationNavHome] = useState(null);
    const [animationNavAboutUs, setAnimationNavAboutUs] = useState(null);
    const [animationNavMeetTheTeam, setAnimationNavMeetTheTeam] = useState(null);
    const [animationNavTac, setAnimationNavTac] = useState(null);
    const [animationTheTeam, setAnimationTheTeam] = useState('fade-2s');
    const [animationP1, setAnimationP1] = useState('fade-2s');
    const [animationMC, setAnimationMC] = useState('fade-2s');
    const [animationAJ, setAnimationAJ] = useState('fade-2s');
    const [animationDM, setAnimationDM] = useState('fade-2s');
    const [animationLH, setAnimationLH] = useState('fade-2s');
    const [animationFooterContainer, setAnimationFooterContainer] = useState('fade-2s');
    const [animationFooterLogo, setAnimationFooterLogo] = useState('fade-2s');
    const [animationFooterHome, setAnimationFooterHome] = useState('fade-2s');
    const [animationFooterAboutUs, setAnimationFooterAboutUs] = useState('fade-2s');
    const [animationFooterMeetTheTeam, setAnimationFooterMeetTheTeam] = useState('fade-2s');
    const [animationFooterTermsAndConditions, setAnimationFooterTermsAndConditions] = useState('fade-2s');
    const [animationFooterContactInfo, setAnimationFooterContactInfo] = useState('fade-2s');
    const [animationFooterAllRR, setAnimationFooterAllRR] = useState('fade-2s');

    const [isNavHidden, setIsNavHidden] = useState(false);
    const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;
            if (prevScrollPos > currentScrollPos) {
                if (isNavHidden) {
                    navBar.style.transform = 'translateY(0)';
                    setIsNavHidden(false);
                }
            } else {
                if (!isNavHidden) {
                    navBar.style.transform = 'translateY(-100%)';
                    setIsNavHidden(true);
                }
            }
            setPrevScrollPos(currentScrollPos);
        };

        const navBar = document.querySelector('nav');
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isNavHidden, prevScrollPos,]);

    const clickedLink = async (page) => {
        switch (page) {
            case 'homeNav':
                setAnimationNavAboutUs('fade-away');
                setAnimationFooterLogo('fade-away');
                setAnimationTheTeam('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationAJ('fade-away');
                setAnimationP1('fade-away');
                setAnimationNavTac('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationFooterContainer('fade-away');
                setAnimationLH('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationNavMeetTheTeam('fade-away');
                setAnimationMC('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationFooterAllRR('fade-away');
                setAnimationDM('fade-away');
                await new Promise(resolve => setTimeout(resolve, (400)));
                setAnimationNavHome('fade-away-2');
                setAnimationNavDesktop('move-away-2c');
                await new Promise(resolve => setTimeout(resolve, (400)));
                window.scrollTo(0, 0);
                await new Promise(resolve => setTimeout(resolve, (200)));
                navigate('/');
                break;
            case 'aboutUsNav':
                setAnimationNavHome('fade-away');
                setAnimationFooterLogo('fade-away');
                setAnimationP1('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationTheTeam('fade-away');
                setAnimationAJ('fade-away');
                setAnimationNavTac('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationFooterContainer('fade-away');
                setAnimationLH('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationMC('fade-away');
                setAnimationNavMeetTheTeam('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationFooterAllRR('fade-away');
                setAnimationDM('fade-away');
                await new Promise(resolve => setTimeout(resolve, (400)));
                setAnimationNavAboutUs('fade-away-2');
                setAnimationNavDesktop('move-away-2c');
                await new Promise(resolve => setTimeout(resolve, (400)));
                window.scrollTo(0, 0);
                await new Promise(resolve => setTimeout(resolve, (400)));
                navigate('/about-us');
                break;
            case 'termsAndConditionsNav':
                setAnimationNavHome('fade-away');
                setAnimationP1('fade-away');
                setAnimationFooterLogo('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationTheTeam('fade-away');
                setAnimationAJ('fade-away');
                setAnimationNavMeetTheTeam('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationFooterContainer('fade-away');
                setAnimationLH('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationMC('fade-away');
                setAnimationNavAboutUs('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationFooterAllRR('fade-away');
                setAnimationDM('fade-away');
                await new Promise(resolve => setTimeout(resolve, (400)));
                setAnimationNavTac('fade-away-2');
                setAnimationNavDesktop('move-away-2c');
                await new Promise(resolve => setTimeout(resolve, (400)));
                window.scrollTo(0, 0);
                await new Promise(resolve => setTimeout(resolve, (600)));
                navigate('/terms-and-conditions');
                break;
            case 'aboutUs':
                setAnimationFooterHome('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationFooterLogo('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationLH('fade-away');
                setAnimationTheTeam('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationAJ('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationFooterTermsAndConditions('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationFooterContactInfo('fade-away');
                setAnimationMC('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationFooterMeetTheTeam('fade-away');
                setAnimationDM('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationFooterAllRR('fade-away');
                setAnimationP1('fade-away');
                await new Promise(resolve => setTimeout(resolve, (400)));
                setAnimationFooterAboutUs('fade-away-2');
                setAnimationFooterContainer('move-away-2');
                await new Promise(resolve => setTimeout(resolve, (500)));
                window.scrollTo(0, 0);
                await new Promise(resolve => setTimeout(resolve, (500)));
                navigate('/about-us');
                break;
            case 'home':
                setAnimationFooterAboutUs('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationFooterLogo('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationLH('fade-away');
                setAnimationP1('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationAJ('fade-away');
                setAnimationTheTeam('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationFooterTermsAndConditions('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationFooterContactInfo('fade-away');
                setAnimationMC('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationFooterMeetTheTeam('fade-away');
                setAnimationDM('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationFooterAllRR('fade-away');
                await new Promise(resolve => setTimeout(resolve, (400)));
                setAnimationFooterHome('fade-away-2');
                setAnimationFooterContainer('move-away-2');
                await new Promise(resolve => setTimeout(resolve, (500)));
                window.scrollTo(0, 0);
                await new Promise(resolve => setTimeout(resolve, (500)));
                navigate('/');
                break;
            case 'termsAndConditions':
                setAnimationFooterHome('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationFooterLogo('fade-away');
                setAnimationP1('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationLH('fade-away');
                setAnimationTheTeam('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationAJ('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationFooterAboutUs('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationFooterContactInfo('fade-away');
                setAnimationMC('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationFooterMeetTheTeam('fade-away');
                setAnimationDM('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationFooterAllRR('fade-away');
                await new Promise(resolve => setTimeout(resolve, (400)));
                setAnimationFooterTermsAndConditions('fade-away-2');
                setAnimationFooterContainer('move-away-2');
                await new Promise(resolve => setTimeout(resolve, (500)));
                window.scrollTo(0, 0);
                await new Promise(resolve => setTimeout(resolve, (600)));
                navigate('/terms-and-conditions');
                break;
        }
    }

    return (
        <div id="team-page" className="d-flex flex-column align-items-center">
            <nav id="nav-desktop" className={`${animationNavDesktop} c-1-bg d-flex flex-column w-100`}>
                <div id="nav-desktop-container" className="d-flex align-items-center justify-content-end w-100">
                    <button className={`${animationNavHome} c-8 h-100`} onClick={() => clickedLink('homeNav')}>Home</button>
                    <button className={`${animationNavAboutUs} c-8 h-100`} onClick={() => clickedLink('aboutUsNav')}>About us</button>
                    <button className={`${animationNavMeetTheTeam} c-8 h-100`}>Team</button>
                    <button id="terms-button-nav" className={`${animationNavTac} c-8 h-100`} onClick={() => clickedLink('termsAndConditionsNav')}>Terms & Conditions</button>
                </div>
            </nav>
            <div className="section-margin-nav"></div>
            <div className="team-container d-flex flex-column align-items-center">
                <div className="section-margin-5"></div>
                <h1 className={animationTheTeam}>The team</h1>
                <p className={animationP1}>Meet the team behind our unique designs</p>
                <div className="section-margin-5"></div>
                <div className="d-flex">
                    <div className="d-flex flex-column align-items-center">
                        <div className={`${animationMC} d-flex team-card team-card-1 team-card-no-hover`}>
                            <img className="team-img team-img-1" src={MC} alt="Michael Coleman" />
                            <div className="team-card-content d-flex flex-column align-items-center justify-content-center">
                                <h3>Michael Coleman</h3>
                                <p className="c-8 mt-1">CEO & Lead Architect</p>
                            </div>
                        </div>
                        <div className={`${animationAJ} d-flex team-card team-card-2 team-card-no-hover`}>
                            <div className="team-card-content d-flex flex-column align-items-center justify-content-center">
                                <h3>Albert Johnson</h3>
                                <p className="c-8 mt-1">Architect</p>
                            </div>
                            <img className="team-img team-img-2" src={AJ} alt="Albert Johnson" />
                        </div>
                        <div className={`${animationDM} d-flex team-card team-card-1 team-card-no-hover`}>
                            <img className="team-img team-img-1" src={DM} alt="Dennis Coleman" />
                            <div className="team-card-content d-flex flex-column align-items-center justify-content-center">
                                <h3>Dennis Coleman</h3>
                                <p className="c-8 mt-1">Architect</p>
                            </div>
                        </div>
                        <div className={`${animationLH} d-flex team-card team-card-2 team-card-no-hover`}>
                            <div className="team-card-content d-flex flex-column align-items-center justify-content-center">
                                <h3>Lauren Haughton</h3>
                                <p className="c-8 mt-1">Operations Manager</p>
                            </div>
                            <img className="team-img team-img-2" src={LH} alt="Lauren Haughton" />
                        </div>
                    </div>
                    <div id="team-card-hovers" className="flex-column align-items-center">
                        <div className="d-flex flex-column justify-content-between team-card team-card-1 team-card-hover">
                            <div></div>
                            <p className="w-100 text-center">"Since the day I founded the firm I knew we were going to achieve fantastic things"</p>
                            <div className="d-flex justify-content-end">
                                <h3 className="signature c-8">M.Coleman</h3>
                            </div>
                        </div>
                        <div className="d-flex flex-column justify-content-between team-card team-card-2 team-card-hover">
                            <div></div>
                            <p className="w-100 text-center">"I love being part of this awesome group of people. I consider them family"</p>
                            <div className="d-flex justify-content-end">
                                <h3 className="signature c-8">A.Johnson</h3>
                            </div>
                        </div>
                        <div className="d-flex flex-column justify-content-between team-card team-card-1 team-card-hover">
                            <div></div>
                            <p className="w-100 text-center">"I really like working with my dad (Michael), Albert and Lauren. We make a great team"</p>
                            <div className="d-flex justify-content-end">
                                <h3 className="signature c-8">D.Coleman</h3>
                            </div>
                        </div>
                        <div className="d-flex flex-column justify-content-between team-card team-card-2 team-card-hover">
                            <div></div>
                            <p className="w-100 text-center">"Michael, Albert and Dennis are some of the best architects I know. They are also wonderful individuals"</p>
                            <div className="d-flex justify-content-end">
                                <h3 className="signature c-8">L.Haughton</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-margin"></div>
            </div>
            <div id="footer" className={`${animationFooterContainer} w-100 c-8-bg d-flex flex-column align-items-center`}>
                <div className="section-margin-footer w-100 c-6-bg"></div>
                <div id="footer-content" className="d-flex align-items-start justify-content-center w-100">
                    <div id="footer-mobile-div"></div>
                    <div className="footer-section d-flex justify-content-center">
                        <img id="logo-img-footer" className={animationFooterLogo} src={logoWhite} alt="logo" />
                    </div>
                    <div id="footer-mobile-div"></div>
                    <div className="footer-section d-flex justify-content-center">
                        <div className="d-flex flex-column">
                            <button className={`${animationFooterHome} white-1`} onClick={() => clickedLink('home')}>Home</button>
                            <button className={`${animationFooterAboutUs} white-1`} onClick={() => clickedLink('aboutUs')}>About us</button>
                            <button id="team-button" className={`${animationFooterMeetTheTeam} white-1`}>Meet the team</button>
                            <button id="terms-button" className={`${animationFooterTermsAndConditions} white-1`} onClick={() => clickedLink('termsAndConditions')}>Terms & Conditions</button>
                        </div>
                    </div>
                    <div id="footer-container-contact" className="footer-section justify-content-center">
                        <div className={`${animationFooterContactInfo} d-flex flex-column justify-content-top white-1`}>
                            <h3 id="contact-adress">608 16th St, Oakland, CA 94612, USA</h3>
                            <h3>510-555-0198</h3>
                            <h3>contact@siltstone.com</h3>
                        </div>
                    </div>
                </div>
                <h5 id="all-r-reserved" className={`${animationFooterAllRR} c-5 footer-bottom`}>All rights reserved | Siltstone Design Group</h5>
                <h5 className={`${animationFooterAllRR} c-5 footer-bottom-2 pb-4`}>Made by Ivo Gonsé</h5>
            </div>
        </div>
    );
}

export default MeetTheTeam;
