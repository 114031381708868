import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import logoWhite from '../assets/img/logo-white.png'

const TermsAndConditions = () => {
    const navigate = useNavigate();

    const [animationNavDesktop, setAnimationNavDesktop] = useState('move-3');
    const [animationNavHome, setAnimationNavHome] = useState(null);
    const [animationNavAboutUs, setAnimationNavAboutUs] = useState(null);
    const [animationNavMeetTheTeam, setAnimationNavMeetTheTeam] = useState(null);
    const [animationNavTac, setAnimationNavTac] = useState(null);
    const [animationTermsAndConditions, setAnimationTermsAndConditions] = useState('fade-2s');
    const [animationP1, setAnimationP1] = useState('hidden');
    const [animationP2, setAnimationP2] = useState('hidden');
    const [animationP3, setAnimationP3] = useState('hidden');
    const [animationP4, setAnimationP4] = useState('hidden');
    const [animationFooterContainer, setAnimationFooterContainer] = useState('fade-2s');
    const [animationFooterLogo, setAnimationFooterLogo] = useState('fade-2s');
    const [animationFooterHome, setAnimationFooterHome] = useState('fade-2s');
    const [animationFooterAboutUs, setAnimationFooterAboutUs] = useState('fade-2s');
    const [animationFooterMeetTheTeam, setAnimationFooterMeetTheTeam] = useState('fade-2s');
    const [animationFooterTermsAndConditions, setAnimationFooterTermsAndConditions] = useState('fade-2s');
    const [animationFooterContactInfo, setAnimationFooterContactInfo] = useState('fade-2s');
    const [animationFooterAllRR, setAnimationFooterAllRR] = useState('fade-2s');

    const [loaded, setLoaded] = useState(0);

    const [isNavHidden, setIsNavHidden] = useState(false);
    const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);

    useEffect(() => {
        if (loaded == 0) {
            setLoaded(1);
            window.scrollTo(0, 10);
            window.scrollTo(0, 0);
        }
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;
            if (prevScrollPos > currentScrollPos) {
                if (isNavHidden) {
                    navBar.style.transform = 'translateY(0)';
                    setIsNavHidden(false);
                }
            } else {
                if (!isNavHidden) {
                    navBar.style.transform = 'translateY(-100%)';
                    setIsNavHidden(true);
                }
            }
            setPrevScrollPos(currentScrollPos);
        };

        const navBar = document.querySelector('nav');
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isNavHidden, prevScrollPos,]);

    const clickedLink = async (page) => {
        switch (page) {
            case 'homeNav':
                setAnimationNavAboutUs('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationFooterLogo('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationP1('fade-away');
                setAnimationTermsAndConditions('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationP2('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationNavTac('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationFooterContainer('fade-away');
                setAnimationP3('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationNavMeetTheTeam('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationFooterAllRR('fade-away');
                setAnimationP4('fade-away');
                await new Promise(resolve => setTimeout(resolve, (400)));
                setAnimationNavHome('fade-away-2');
                setAnimationNavDesktop('move-away-2c');
                await new Promise(resolve => setTimeout(resolve, (400)));
                window.scrollTo(0, 0);
                await new Promise(resolve => setTimeout(resolve, (200)));
                navigate('/');
                break;
            case 'aboutUsNav':
                setAnimationNavHome('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationFooterLogo('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationP1('fade-away');
                setAnimationTermsAndConditions('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationP2('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationNavTac('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationFooterContainer('fade-away');
                setAnimationP3('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationNavMeetTheTeam('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationFooterAllRR('fade-away');
                setAnimationP4('fade-away');
                await new Promise(resolve => setTimeout(resolve, (400)));
                setAnimationNavAboutUs('fade-away-2');
                setAnimationNavDesktop('move-away-2c');
                await new Promise(resolve => setTimeout(resolve, (400)));
                window.scrollTo(0, 0);
                await new Promise(resolve => setTimeout(resolve, (400)));
                navigate('/about-us');
                break;
            case 'meetTheTeamNav':
                setAnimationNavHome('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationFooterLogo('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationP1('fade-away');
                setAnimationTermsAndConditions('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationP2('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationNavTac('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationFooterContainer('fade-away');
                setAnimationP3('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationNavAboutUs('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationFooterAllRR('fade-away');
                setAnimationP4('fade-away');
                await new Promise(resolve => setTimeout(resolve, (400)));
                setAnimationNavMeetTheTeam('fade-away-2');
                setAnimationNavDesktop('move-away-2c');
                await new Promise(resolve => setTimeout(resolve, (400)));
                window.scrollTo(0, 0);
                await new Promise(resolve => setTimeout(resolve, (400)));
                navigate('/team');
                break;
            case 'aboutUs':
                setAnimationFooterHome('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationFooterLogo('fade-away');
                setAnimationNavDesktop('fade-away')
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationP1('fade-away');
                setAnimationTermsAndConditions('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationP2('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationFooterTermsAndConditions('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationFooterContactInfo('fade-away');
                setAnimationP3('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationFooterMeetTheTeam('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationFooterAllRR('fade-away');
                setAnimationP4('fade-away');
                await new Promise(resolve => setTimeout(resolve, (400)));
                setAnimationFooterAboutUs('fade-away-2');
                setAnimationFooterContainer('move-away-2');
                await new Promise(resolve => setTimeout(resolve, (500)));
                window.scrollTo(0, 0);
                await new Promise(resolve => setTimeout(resolve, (500)));
                navigate('/about-us');
                break;
            case 'home':
                setAnimationFooterAboutUs('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationFooterLogo('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationP1('fade-away');
                setAnimationP2('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationP3('fade-away');
                setAnimationNavDesktop('fade-away')
                setAnimationTermsAndConditions('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationFooterTermsAndConditions('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationFooterContactInfo('fade-away');
                setAnimationP4('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationFooterMeetTheTeam('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationFooterAllRR('fade-away');
                await new Promise(resolve => setTimeout(resolve, (400)));
                setAnimationFooterHome('fade-away-2');
                setAnimationFooterContainer('move-away-2');
                await new Promise(resolve => setTimeout(resolve, (500)));
                window.scrollTo(0, 0);
                await new Promise(resolve => setTimeout(resolve, (500)));
                navigate('/');
                break;
            case 'meetTheTeam':
                setAnimationFooterHome('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationFooterLogo('fade-away');
                setAnimationP1('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationP2('fade-away');
                setAnimationNavDesktop('fade-away')
                setAnimationTermsAndConditions('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationP3('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationFooterAboutUs('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationFooterContactInfo('fade-away');
                setAnimationP4('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationFooterTermsAndConditions('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationFooterAllRR('fade-away');
                await new Promise(resolve => setTimeout(resolve, (400)));
                setAnimationFooterMeetTheTeam('fade-away-2');
                setAnimationFooterContainer('move-away-2');
                await new Promise(resolve => setTimeout(resolve, (500)));
                window.scrollTo(0, 0);
                await new Promise(resolve => setTimeout(resolve, (500)));
                navigate('/team');
                break;
        }
    }

    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                entry.target.classList.add('show');
            }
        })
    });

    const paragraphs = document.querySelectorAll('.hidden');
    paragraphs.forEach((el) => observer.observe(el));

    return (
        <div id="tac-page" className="d-flex flex-column align-items-center">
            <nav id="nav-desktop" className={`${animationNavDesktop} c-1-bg d-flex flex-column w-100`}>
                <div id="nav-desktop-container" className="d-flex align-items-center justify-content-end w-100">
                    <button className={`${animationNavHome} c-8 h-100`} onClick={() => clickedLink('homeNav')}>Home</button>
                    <button className={`${animationNavAboutUs} c-8 h-100`} onClick={() => clickedLink('aboutUsNav')}>About us</button>
                    <button className={`${animationNavMeetTheTeam} c-8 h-100`} onClick={() => clickedLink('meetTheTeamNav')}>Team</button>
                    <button id="terms-button-nav" className={`${animationNavTac} c-8 h-100`}>Terms & Conditions</button>
                </div>
            </nav>
            <div className="section-margin-nav"></div>
            <div className="team-container d-flex flex-column align-items-center">
                <div className="section-margin-5"></div>
                <h1 className={animationTermsAndConditions}>Terms & Conditions</h1>
                <div className="section-margin-3"></div>
                <p className={animationP2}>Welcome to Siltstone Design Group's website. Please carefully read the following terms and conditions governing your use of this website. By accessing, browsing, or using this website, you acknowledge that you have read, understood, and agree to be bound by these Terms and to comply with all applicable laws and regulations. If you do not agree to these Terms, please do not use this website.</p>
                <div className="section-margin-3"></div>
                <p className={`${animationP1} regular`}>Website Content:</p>
                <p className={animationP3}>The content of this website, including but not limited to text, images, graphics, logos, designs, and software, is the property of Siltstone Design Group or its licensors and is protected by copyright and other intellectual property laws.</p>
                <p className={animationP1}>You may access and use the content on this website solely for your personal, non-commercial use. Any other use, including reproduction, modification, distribution, or republication of the content, without prior written permission from Siltstone Design Group, is strictly prohibited.</p>
                <div className="section-margin-3"></div>
                <p className={`${animationP1} regular`}>Project Images and Details:</p>
                <p className={animationP4}>The project images and details displayed on this website are provided for informational purposes only. They represent the past work of Siltstone Design Group and may not reflect current projects or services.</p>
                <p className={animationP3}>Siltstone Design Group makes no representations or warranties regarding the accuracy, completeness, or reliability of the project images and details. You acknowledge that reliance on any information provided on this website is at your own risk.</p>
                <div className="section-margin-3"></div>
                <p className={`${animationP1} regular`}>User Conduct:</p>
                <p className={animationP2}>You agree to use this website only for lawful purposes and in a manner that does not infringe upon the rights of others or inhibit their use and enjoyment of the website.</p>
                <p className={animationP4}>You agree not to upload, post, transmit, or distribute any content that is unlawful, defamatory, obscene, threatening, or otherwise objectionable.</p>
                <div className="section-margin-3"></div>
                <p className={`${animationP1} regular`}>Third-Party Links:</p>
                <p className={animationP1}>This website may contain links to third-party websites that are not owned or controlled by Siltstone Design Group. Siltstone Design Group assumes no responsibility for the content, privacy policies, or practices of any third-party websites.</p>
                <p className={animationP2}>Your use of third-party websites is subject to the terms and conditions and privacy policies of those websites.</p>
                <div className="section-margin-3"></div>
                <p className={`${animationP1} regular`}>Disclaimer of Warranties:</p>
                <p className={animationP3}>This website is provided on an "as is" and "as available" basis. Siltstone Design Group makes no representations or warranties of any kind, express or implied, regarding the operation or availability of this website or the content, materials, products, or services included on this website.</p>
                <p className={animationP1}>To the fullest extent permitted by applicable law, Siltstone Design Group disclaims all warranties, express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, and non-infringement.</p>
                <div className="section-margin-3"></div>
                <p className={`${animationP1} regular`}>Limitation of Liability:</p>
                <p className={animationP4}>In no event shall Siltstone Design Group be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in any way connected with your use of this website or the content, materials, products, or services included on this website.</p>
                <p className={animationP3}>Siltstone Design Group's total liability to you for any damages shall not exceed the amount paid by you, if any, for accessing this website.</p>
                <div className="section-margin-3"></div>
                <p className={`${animationP1} regular`}>Governing Law:</p>
                <p className={animationP2}>These Terms shall be governed by and construed in accordance with the laws of United States Of America, without regard to its conflict of law principles.</p>
                <p className={animationP4}>Any dispute arising out of or relating to these Terms or your use of this website shall be subject to the exclusive jurisdiction of the courts of United States Of America.</p>
                <div className="section-margin-3"></div>
                <p className={`${animationP1} regular`}>Changes to Terms:</p>
                <p className={animationP1}>Siltstone Design Group reserves the right to modify or replace these Terms at any time. It is your responsibility to check these Terms periodically for changes. Your continued use of this website following the posting of any changes to these Terms constitutes acceptance of those changes.</p>
                <div className="section-margin-3"></div>
                <p className={animationP3}>By using this website, you signify your acceptance of these Terms and agree to comply with them. If you do not agree to these Terms, please refrain from using this website. Thank you for visiting Siltstone Design Group's website.</p>
            </div>
            <div className="section-margin-2"></div>
            <div id="footer" className={`${animationFooterContainer} w-100 c-8-bg d-flex flex-column align-items-center`}>
                <div className="section-margin-footer w-100 c-6-bg"></div>
                <div id="footer-content" className="d-flex align-items-start justify-content-center w-100">
                    <div id="footer-mobile-div"></div>
                    <div className="footer-section d-flex justify-content-center">
                        <img id="logo-img-footer" className={animationFooterLogo} src={logoWhite} alt="logo" />
                    </div>
                    <div id="footer-mobile-div"></div>
                    <div className="footer-section d-flex justify-content-center">
                        <div className="d-flex flex-column">
                            <button className={`${animationFooterHome} white-1`} onClick={() => clickedLink('home')}>Home</button>
                            <button className={`${animationFooterAboutUs} white-1`} onClick={() => clickedLink('aboutUs')}>About us</button>
                            <button id="team-button" className={`${animationFooterMeetTheTeam} white-1`} onClick={() => clickedLink('meetTheTeam')}>Meet the team</button>
                            <button id="terms-button" className={`${animationFooterTermsAndConditions} white-1`}>Terms & Conditions</button>
                        </div>
                    </div>
                    <div id="footer-container-contact" className="footer-section justify-content-center">
                        <div className={`${animationFooterContactInfo} d-flex flex-column justify-content-top white-1`}>
                            <h3 id="contact-adress">608 16th St, Oakland, CA 94612, USA</h3>
                            <h3>510-555-0198</h3>
                            <h3>contact@siltstone.com</h3>
                        </div>
                    </div>
                </div>
                <h5 id="all-r-reserved" className={`${animationFooterAllRR} c-5 footer-bottom`}>All rights reserved | Siltstone Design Group</h5>
                <h5 className={`${animationFooterAllRR} c-5 footer-bottom-2 pb-4`}>Made by Ivo Gonsé</h5>
            </div>
        </div>
    );
}

export default TermsAndConditions;
