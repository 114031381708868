import { Routes, Route, HashRouter } from "react-router-dom";

import './assets/css/styles.css'

import MainMenu from './components/MainMenu.jsx'
import MeetTheTeam from './components/MeetTheTeam.jsx'
import TermsAndConditions from './components/TermsAndConditions.jsx'
import AboutUs from './components/AboutUs.jsx'
import Project1 from './components/Project3.jsx'
import Project2 from './components/Project4.jsx'
import Project3 from './components/Project6.jsx'
import Project4 from './components/Project7.jsx'
import Project5 from './components/Project9.jsx'
import Project6 from './components/Project10.jsx'
import Project7 from './components/Project11.jsx'

function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path='/' element={<MainMenu />} />
        <Route path='/team' element={<MeetTheTeam />} />
        <Route path='/terms-and-conditions' element={<TermsAndConditions />} />
        <Route path='/about-us' element={<AboutUs />} />
        <Route path='/project-id-3' element={<Project1 />} />
        <Route path='/project-id-4' element={<Project2 />} />
        <Route path='/project-id-6' element={<Project3 />} />
        <Route path='/project-id-7' element={<Project4 />} />
        <Route path='/project-id-9' element={<Project5 />} />
        <Route path='/project-id-10' element={<Project6 />} />
        <Route path='/project-id-11' element={<Project7 />} />
      </Routes>
    </HashRouter>
  );
}

export default App;