import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import logoWhite from '../assets/img/logo-white.png'

import img1 from '../assets/img/house-3/img-1.webp';
import img3 from '../assets/img/house-3/img-3.webp';
import img6 from '../assets/img/house-3/img-6.webp';
import img8 from '../assets/img/house-3/img-8.webp';
import img9 from '../assets/img/house-3/img-9.webp';
import img10 from '../assets/img/house-3/img-10.webp';
import img11 from '../assets/img/house-3/img-11.webp';
import img12 from '../assets/img/house-3/img-12.webp';
import img13 from '../assets/img/house-3/img-13.webp';
import img14 from '../assets/img/house-3/img-14.webp';

const Project6 = () => {
    const navigate = useNavigate();

    const [animationNavDesktop, setAnimationNavDesktop] = useState('move-3');
    const [animationNavHome, setAnimationNavHome] = useState(null);
    const [animationNavAboutUs, setAnimationNavAboutUs] = useState(null);
    const [animationNavMeetTheTeam, setAnimationNavMeetTheTeam] = useState(null);
    const [animationNavTac, setAnimationNavTac] = useState(null);
    const [animationGallery, setAnimationGallery] = useState('fade-2s');
    const [animationP1, setAnimationP1] = useState('fade-2s');
    const [animationP2, setAnimationP2] = useState('fade-2s');
    const [animationP3, setAnimationP3] = useState('fade-2s');
    const [animationImg1, setAnimationImg1] = useState('fade-2s');
    const [animationImg3, setAnimationImg3] = useState('fade-2s');
    const [animationImg6, setAnimationImg6] = useState('fade-2s');
    const [animationImg8, setAnimationImg8] = useState('fade-2s');
    const [animationImg9, setAnimationImg9] = useState('fade-2s');
    const [animationImg10, setAnimationImg10] = useState('fade-2s');
    const [animationImg11, setAnimationImg11] = useState('fade-2s');
    const [animationImg12, setAnimationImg12] = useState('fade-2s');
    const [animationImg13, setAnimationImg13] = useState('fade-2s');
    const [animationImg14, setAnimationImg14] = useState('fade-2s');
    const [animationFooterContainer, setAnimationFooterContainer] = useState('fade-2s');
    const [animationFooterLogo, setAnimationFooterLogo] = useState('fade-2s');
    const [animationFooterHome, setAnimationFooterHome] = useState('fade-2s');
    const [animationFooterAboutUs, setAnimationFooterAboutUs] = useState('fade-2s');
    const [animationFooterMeetTheTeam, setAnimationFooterMeetTheTeam] = useState('fade-2s');
    const [animationFooterTermsAndConditions, setAnimationFooterTermsAndConditions] = useState('fade-2s');
    const [animationFooterContactInfo, setAnimationFooterContactInfo] = useState('fade-2s');
    const [animationFooterAllRR, setAnimationFooterAllRR] = useState('fade-2s');

    const [isNavHidden, setIsNavHidden] = useState(false);
    const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;
            if (prevScrollPos > currentScrollPos) {
                if (isNavHidden) {
                    navBar.style.transform = 'translateY(0)';
                    setIsNavHidden(false);
                }
            } else {
                if (!isNavHidden) {
                    navBar.style.transform = 'translateY(-100%)';
                    setIsNavHidden(true);
                }
            }
            setPrevScrollPos(currentScrollPos);
        };

        const navBar = document.querySelector('nav');
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isNavHidden, prevScrollPos,]);
    
    const clickedLink = async (page) => {
        switch (page) {
            case 'homeNav':
                setAnimationNavAboutUs('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationImg1('fade-away');
                setAnimationImg3('fade-away');
                setAnimationFooterLogo('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationImg6('fade-away');
                setAnimationP2('fade-away');
                setAnimationGallery('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationImg14('fade-away');
                setAnimationImg9('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationImg10('fade-away');
                setAnimationImg8('fade-away');
                setAnimationImg12('fade-away');
                setAnimationNavTac('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationImg13('fade-away');
                setAnimationImg11('fade-away');
                setAnimationFooterContainer('fade-away');
                setAnimationP3('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationNavMeetTheTeam('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationFooterAllRR('fade-away');
                setAnimationP1('fade-away');
                await new Promise(resolve => setTimeout(resolve, (400)));
                setAnimationNavHome('fade-away-2');
                setAnimationNavDesktop('move-away-2c');
                await new Promise(resolve => setTimeout(resolve, (400)));
                window.scrollTo(0, 0);
                await new Promise(resolve => setTimeout(resolve, (200)));
                navigate('/');
                break;
            case 'aboutUsNav':
                setAnimationNavHome('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationImg1('fade-away');
                setAnimationImg3('fade-away');
                setAnimationFooterLogo('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationImg6('fade-away');
                setAnimationP2('fade-away');
                setAnimationGallery('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationImg14('fade-away');
                setAnimationImg9('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationImg10('fade-away');
                setAnimationImg8('fade-away');
                setAnimationImg12('fade-away');
                setAnimationNavTac('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationImg13('fade-away');
                setAnimationImg11('fade-away');
                setAnimationFooterContainer('fade-away');
                setAnimationP3('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationNavMeetTheTeam('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationFooterAllRR('fade-away');
                setAnimationP1('fade-away');
                await new Promise(resolve => setTimeout(resolve, (400)));
                setAnimationNavAboutUs('fade-away-2');
                setAnimationNavDesktop('move-away-2c');
                await new Promise(resolve => setTimeout(resolve, (400)));
                window.scrollTo(0, 0);
                await new Promise(resolve => setTimeout(resolve, (200)));
                navigate('/about-us');
                break;
            case 'meetTheTeamNav':
                setAnimationNavAboutUs('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationImg1('fade-away');
                setAnimationImg3('fade-away');
                setAnimationFooterLogo('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationImg6('fade-away');
                setAnimationP2('fade-away');
                setAnimationGallery('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationImg14('fade-away');
                setAnimationImg9('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationImg10('fade-away');
                setAnimationImg8('fade-away');
                setAnimationImg12('fade-away');
                setAnimationNavTac('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationImg13('fade-away');
                setAnimationImg11('fade-away');
                setAnimationFooterContainer('fade-away');
                setAnimationP3('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationNavHome('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationFooterAllRR('fade-away');
                setAnimationP1('fade-away');
                await new Promise(resolve => setTimeout(resolve, (400)));
                setAnimationNavMeetTheTeam('fade-away-2');
                setAnimationNavDesktop('move-away-2c');
                await new Promise(resolve => setTimeout(resolve, (400)));
                window.scrollTo(0, 0);
                await new Promise(resolve => setTimeout(resolve, (200)));
                navigate('/team');
                break;
            case 'termsAndConditionsNav':
                setAnimationNavAboutUs('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationImg1('fade-away');
                setAnimationImg3('fade-away');
                setAnimationFooterLogo('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationImg6('fade-away');
                setAnimationP2('fade-away');
                setAnimationGallery('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationImg14('fade-away');
                setAnimationImg9('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationImg10('fade-away');
                setAnimationImg8('fade-away');
                setAnimationImg12('fade-away');
                setAnimationNavHome('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationImg13('fade-away');
                setAnimationImg11('fade-away');
                setAnimationFooterContainer('fade-away');
                setAnimationP3('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationNavMeetTheTeam('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationFooterAllRR('fade-away');
                setAnimationP1('fade-away');
                await new Promise(resolve => setTimeout(resolve, (400)));
                setAnimationNavTac('fade-away-2');
                setAnimationNavDesktop('move-away-2c');
                await new Promise(resolve => setTimeout(resolve, (400)));
                window.scrollTo(0, 0);
                await new Promise(resolve => setTimeout(resolve, (200)));
                navigate('/terms-and-conditions');
                break;
            case 'aboutUs':
                setAnimationImg1('fade-away');
                setAnimationImg3('fade-away');
                setAnimationFooterHome('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationFooterLogo('fade-away');
                setAnimationNavDesktop('fade-away');
                setAnimationImg6('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationP2('fade-away');
                setAnimationGallery('fade-away');
                setAnimationImg14('fade-away');
                setAnimationImg9('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationImg10('fade-away');
                setAnimationImg8('fade-away');
                setAnimationImg12('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationImg13('fade-away');
                setAnimationImg11('fade-away');
                setAnimationFooterTermsAndConditions('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationFooterContactInfo('fade-away');
                setAnimationP3('fade-away');
                setAnimationFooterMeetTheTeam('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationFooterAllRR('fade-away');
                setAnimationP1('fade-away');
                await new Promise(resolve => setTimeout(resolve, (400)));
                setAnimationFooterAboutUs('fade-away-2');
                setAnimationFooterContainer('move-away-2');
                await new Promise(resolve => setTimeout(resolve, (500)));
                window.scrollTo(0, 0);
                await new Promise(resolve => setTimeout(resolve, (500)));
                navigate('/about-us');
                break;
            case 'home':
                setAnimationImg1('fade-away');
                setAnimationImg3('fade-away');
                setAnimationFooterAboutUs('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationFooterLogo('fade-away');
                setAnimationNavDesktop('fade-away');
                setAnimationImg6('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationP2('fade-away');
                setAnimationGallery('fade-away');
                setAnimationImg14('fade-away');
                setAnimationImg9('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationImg10('fade-away');
                setAnimationImg8('fade-away');
                setAnimationImg12('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationImg13('fade-away');
                setAnimationImg11('fade-away');
                setAnimationFooterTermsAndConditions('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationFooterContactInfo('fade-away');
                setAnimationP3('fade-away');
                setAnimationFooterMeetTheTeam('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationFooterAllRR('fade-away');
                setAnimationP1('fade-away');
                await new Promise(resolve => setTimeout(resolve, (400)));
                setAnimationFooterHome('fade-away-2');
                setAnimationFooterContainer('move-away-2');
                await new Promise(resolve => setTimeout(resolve, (500)));
                window.scrollTo(0, 0);
                await new Promise(resolve => setTimeout(resolve, (500)));
                navigate('/');
                break;
            case 'meetTheTeam':
                setAnimationImg1('fade-away');
                setAnimationImg3('fade-away');
                setAnimationFooterHome('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationFooterLogo('fade-away');
                setAnimationNavDesktop('fade-away');
                setAnimationImg6('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationP2('fade-away');
                setAnimationGallery('fade-away');
                setAnimationImg14('fade-away');
                setAnimationImg9('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationImg10('fade-away');
                setAnimationImg8('fade-away');
                setAnimationImg12('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationImg13('fade-away');
                setAnimationImg11('fade-away');
                setAnimationFooterTermsAndConditions('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationFooterContactInfo('fade-away');
                setAnimationP3('fade-away');
                setAnimationFooterAboutUs('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationFooterAllRR('fade-away');
                setAnimationP1('fade-away');
                await new Promise(resolve => setTimeout(resolve, (400)));
                setAnimationFooterMeetTheTeam('fade-away-2');
                setAnimationFooterContainer('move-away-2');
                await new Promise(resolve => setTimeout(resolve, (500)));
                window.scrollTo(0, 0);
                await new Promise(resolve => setTimeout(resolve, (500)));
                navigate('/team');
                break;
            case 'termsAndConditions':
                setAnimationImg1('fade-away');
                setAnimationImg3('fade-away');
                setAnimationFooterHome('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationFooterLogo('fade-away');
                setAnimationNavDesktop('fade-away');
                setAnimationImg6('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationP2('fade-away');
                setAnimationGallery('fade-away');
                setAnimationImg14('fade-away');
                setAnimationImg9('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationImg10('fade-away');
                setAnimationImg8('fade-away');
                setAnimationImg12('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationImg13('fade-away');
                setAnimationImg11('fade-away');
                setAnimationFooterAboutUs('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationFooterContactInfo('fade-away');
                setAnimationP3('fade-away');
                setAnimationFooterMeetTheTeam('fade-away');
                await new Promise(resolve => setTimeout(resolve, (75)));
                setAnimationFooterAllRR('fade-away');
                setAnimationP1('fade-away');
                await new Promise(resolve => setTimeout(resolve, (400)));
                setAnimationFooterTermsAndConditions('fade-away-2');
                setAnimationFooterContainer('move-away-2');
                await new Promise(resolve => setTimeout(resolve, (500)));
                window.scrollTo(0, 0);
                await new Promise(resolve => setTimeout(resolve, (500)));
                navigate('/terms-and-conditions');
                break;
        }
    }

    return (
        <div id="project-page" className="d-flex flex-column align-items-center">
            <nav id="nav-desktop" className={`${animationNavDesktop} c-1-bg d-flex flex-column w-100`}>
                <div id="nav-desktop-container" className="d-flex align-items-center justify-content-end w-100">
                    <button className={`${animationNavHome} c-8 h-100`} onClick={() => clickedLink('homeNav')}>Home</button>
                    <button className={`${animationNavAboutUs} c-8 h-100`} onClick={() => clickedLink('aboutUsNav')}>About us</button>
                    <button className={`${animationNavMeetTheTeam} c-8 h-100`} onClick={() => clickedLink('meetTheTeamNav')}>Team</button>
                    <button id="terms-button-nav" className={`${animationNavTac} c-8 h-100`} onClick={() => clickedLink('termsAndConditionsNav')}>Terms & Conditions</button>
                </div>
            </nav>
            <div className="section-margin-nav"></div>
            <div className="team-container side-margins-15 d-flex flex-column align-items-center">
                <div className="section-margin-5"></div>
                <div className="d-flex w-100 align-items-end justify-content-end">
                    <h1 id="number-project" className={`${animationGallery}`}>#6</h1>
                    <div className={`${animationP1} d-flex flex-column project-details-m project-details-m-2`}>
                        <p className="m-0">2018</p>
                        <p className="m-0">Sacramento</p>
                    </div>
                    <div className={`${animationP2} d-flex flex-column project-details-m project-details-m-2`}>
                        <p className="m-0">306sqm</p>
                    </div>
                    <div className={`${animationP3} d-flex flex-column project-details-m project-details-m-3`}>
                        <p className="m-0">3 bedrooms</p>
                        <p className="m-0">3 bathrooms</p>
                    </div>
                </div>
                <div className="section-margin-5"></div>
                <div id="photo-gallery" className="d-flex flex-column w-100">
                    <img className={`${animationImg1} w-100`} src={img1} alt="img-1" />
                    <div id="photo-gallery-division" className="d-flex w-100">
                        <img className={`${animationImg3} gallery-img-left`} src={img3} alt="img-3" />
                        <img className={`${animationImg9} gallery-img-right`} src={img9} alt="img-9" />
                    </div>
                    <div id="photo-gallery-division" className="d-flex w-100">
                    </div>
                    <img className={`${animationImg6} w-100`} src={img6} alt="img-6" />
                    <div id="photo-gallery-division" className="d-flex w-100">
                        <img className={`${animationImg8} gallery-img-left`} src={img8} alt="img-8" />
                    </div>
                    <img className={`${animationImg10} w-100`} src={img10} alt="img-10" />
                    <div id="photo-gallery-division" className="d-flex w-100">
                        <img className={`${animationImg11} gallery-img-left`} src={img11} alt="img-11" />
                        <img className={`${animationImg14} gallery-img-right`} src={img14} alt="img-14" />
                    </div>
                    <img className={`${animationImg12} w-100`} src={img12} alt="img-12" />
                    <img className={`${animationImg13} w-100`} src={img13} alt="img-13" />
                </div>
            </div>
            <div className="section-margin-2"></div>
            <div id="footer" className={`${animationFooterContainer} w-100 c-8-bg d-flex flex-column align-items-center`}>
                <div className="section-margin-footer w-100 c-6-bg"></div>
                <div id="footer-content" className="d-flex align-items-start justify-content-center w-100">
                    <div id="footer-mobile-div"></div>
                    <div className="footer-section d-flex justify-content-center">
                        <img id="logo-img-footer" className={animationFooterLogo} src={logoWhite} alt="logo" />
                    </div>
                    <div id="footer-mobile-div"></div>
                    <div className="footer-section d-flex justify-content-center">
                        <div className="d-flex flex-column">
                            <button className={`${animationFooterHome} white-1`} onClick={() => clickedLink('home')}>Home</button>
                            <button className={`${animationFooterAboutUs} white-1`} onClick={() => clickedLink('aboutUs')}>About us</button>
                            <button id="team-button" className={`${animationFooterMeetTheTeam} white-1`} onClick={() => clickedLink('meetTheTeam')}>Meet the team</button>
                            <button id="terms-button" className={`${animationFooterTermsAndConditions} white-1`} onClick={() => clickedLink('termsAndConditions')}>Terms & Conditions</button>
                        </div>
                    </div>
                    <div id="footer-container-contact" className="footer-section justify-content-center">
                        <div className={`${animationFooterContactInfo} d-flex flex-column justify-content-top white-1`}>
                            <h3 id="contact-adress">608 16th St, Oakland, CA 94612, USA</h3>
                            <h3>510-555-0198</h3>
                            <h3>contact@siltstone.com</h3>
                        </div>
                    </div>
                </div>
                <h5 id="all-r-reserved" className={`${animationFooterAllRR} c-5 footer-bottom`}>All rights reserved | Siltstone Design Group</h5>
                <h5 className={`${animationFooterAllRR} c-5 footer-bottom-2 pb-4`}>Made by Ivo Gonsé</h5>
            </div>
        </div>
    );
}

export default Project6;