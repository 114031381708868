import React, { useEffect, useRef, useState } from 'react';
import { Parallax, ParallaxLayer } from '@react-spring/parallax';
import { useNavigate } from 'react-router-dom';

import mapImg from '../assets/img/map.webp';
import logoWhite from '../assets/img/logo-white.png';
import arrowDown from '../assets/img/arrow-down.png';

const MainMenu = () => {
    const navigate = useNavigate();
    const refParallax1 = useRef();
    const ref = useRef(null);
    const [map, setMap] = useState();
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;

    const [animationHome, setAnimationHome] = useState('fade-away-2');
    const [animationMobile, setAnimationMobile] = useState('fade-2s');
    const [animationHomeMobile, setAnimationHomeMobile] = useState(null);
    const [animationAboutUs, setAnimationAboutUs] = useState(null);
    const [animationP1, setAnimationP1] = useState(null);
    const [animationP2, setAnimationP2] = useState(null);
    const [animationLearnMore, setAnimationLearnMore] = useState(null);
    const [animationMeetTheTeam, setAnimationMeetTheTeam] = useState(null);
    const [animationImg1, setAnimationImg1] = useState(null);
    const [animationOurSelectedWork, setAnimationOurSelectedWork] = useState(null);
    const [animationWorkMobile, setAnimationWorkMobile] = useState(null);
    const [animationTimeline, setAnimationTimeline] = useState(null);
    const [animationHouse1, setAnimationHouse1] = useState(null);
    const [animationHouse2, setAnimationHouse2] = useState(null);
    const [animationHouse3, setAnimationHouse3] = useState(null);
    const [animationHouse4, setAnimationHouse4] = useState(null);
    const [animationHouse5, setAnimationHouse5] = useState(null);
    const [animationHouse6, setAnimationHouse6] = useState(null);
    const [animationHouse7, setAnimationHouse7] = useState(null);
    const [animationContactUsMap, setAnimationContactUsMap] = useState(null);
    const [animationContactUsContent, setAnimationContactUsContent] = useState(null);
    const [animationContactUsContainer, setAnimationContactUsContainer] = useState(null);
    const [animationContactUs, setAnimationContactUs] = useState(null);
    const [animationFooterLogo, setAnimationFooterLogo] = useState(null);
    const [animationFooterHome, setAnimationFooterHome] = useState(null);
    const [animationFooterAboutUs, setAnimationFooterAboutUs] = useState(null);
    const [animationFooterMeetTheTeam, setAnimationFooterMeetTheTeam] = useState(null);
    const [animationFooterTermsAndConditions, setAnimationFooterTermsAndConditions] = useState(null);
    const [animationFooterContactInfo, setAnimationFooterContactInfo] = useState(null);
    const [animationFooterAllRR, setAnimationFooterAllRR] = useState(null);
    const [animationFooterContainer, setAnimationFooterContainer] = useState(null);

    const [houseCardStatus, setHouseCardStatus] = useState('house-card-data-hover');

    useEffect(() => {
        if (ref.current && !map) {
            setMap(new window.google.maps.Map(ref.current, {}));
        }
    }, [ref, map]);

    const calculatePages = () => {
        switch (true) {
            case screenWidth > 2000:
                return (4600 / screenHeight + 1);
            case screenWidth > 1800:
                return (4100 / screenHeight + 1);
            case screenWidth > 1600:
                return (3750 / screenHeight + 1);
            case screenWidth > 1400:
                return (3400 / screenHeight + 1);
            case screenWidth > 1200:
                return (2950 / screenHeight + 1);
            case screenWidth > 1000:
                return (2550 / screenHeight + 1);
            default:
                return (2140 / screenHeight + 1);
        }
    }

    const clickedHome = () => {
        switch (true) {
            case screenWidth > 1800:
                refParallax1.current.scrollTo(0.7 + 45 / screenHeight);
                break;
            default:
                refParallax1.current.scrollTo(0.72 + 45 / screenHeight);
                break;
        }
        setAnimationAboutUs('fade-2s');
        setAnimationP1('fade-2-3s');
        setAnimationP2('fade-2-6s');
        setAnimationLearnMore('fade-2-9s');
        setAnimationMeetTheTeam('fade-3-2s');
    }

    const clickedLink = async (page) => {
        switch (page) {
            case 'learnMore':
                setAnimationHome('fade-away');
                setAnimationHomeMobile('fade-away');
                setAnimationTimeline('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationHouse3('fade-away');
                setAnimationImg1('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationHouse2('fade-away');
                setAnimationP1('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationHouse4('fade-away');
                setAnimationMeetTheTeam('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationHouse5('fade-away');
                setAnimationAboutUs('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationHouse1('fade-away');
                setAnimationP2('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationHouse6('fade-away');
                setAnimationContactUs('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationOurSelectedWork('fade-away');
                setAnimationWorkMobile('fade-away');
                await new Promise(resolve => setTimeout(resolve, (400)));
                setAnimationLearnMore('fade-away-2');
                await new Promise(resolve => setTimeout(resolve, (1000)));
                window.scrollTo(0, 0);
                await new Promise(resolve => setTimeout(resolve, (200)));
                navigate('/about-us');
            break;
            case 'meetTheTeam':
                setAnimationHome('fade-away');
                setAnimationHomeMobile('fade-away');
                setAnimationTimeline('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationHouse3('fade-away');
                setAnimationImg1('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationHouse2('fade-away');
                setAnimationP1('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationHouse4('fade-away');
                setAnimationLearnMore('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationHouse5('fade-away');
                setAnimationAboutUs('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationHouse1('fade-away');
                setAnimationP2('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationHouse6('fade-away');
                setAnimationContactUs('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationOurSelectedWork('fade-away');
                setAnimationWorkMobile('fade-away');
                await new Promise(resolve => setTimeout(resolve, (400)));
                setAnimationMeetTheTeam('fade-away-2');
                await new Promise(resolve => setTimeout(resolve, (1000)));
                window.scrollTo(0, 0);
                await new Promise(resolve => setTimeout(resolve, (200)));
                navigate('/team');
            break;
            case 1:
                setAnimationHome('fade-away');
                setAnimationHomeMobile('fade-away');
                setHouseCardStatus('house-card-data-no-hover');
                setAnimationTimeline('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationImg1('fade-away');
                setAnimationHouse3('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationP1('fade-away');
                setAnimationHouse2('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationLearnMore('fade-away');
                setAnimationHouse4('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationAboutUs('fade-away');
                setAnimationHouse6('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationP2('fade-away');
                setAnimationHouse5('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationMeetTheTeam('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationOurSelectedWork('fade-away');
                setAnimationHouse7('fade-away');
                setAnimationWorkMobile('fade-away');
                await new Promise(resolve => setTimeout(resolve, (400)));
                setAnimationHouse1('fade-away-2');
                await new Promise(resolve => setTimeout(resolve, (1000)));
                window.scrollTo(0, 0);
                await new Promise(resolve => setTimeout(resolve, (200)));
                navigate('/project-id-3');
            break;
            case 2:
                setAnimationHome('fade-away');
                setAnimationHomeMobile('fade-away');
                setHouseCardStatus('house-card-data-no-hover');
                setAnimationTimeline('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationImg1('fade-away');
                setAnimationHouse3('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationP1('fade-away');
                setAnimationHouse1('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationLearnMore('fade-away');
                setAnimationHouse4('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationAboutUs('fade-away');
                setAnimationHouse6('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationP2('fade-away');
                setAnimationHouse5('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationMeetTheTeam('fade-away');
                setAnimationContactUs('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationOurSelectedWork('fade-away');
                setAnimationHouse7('fade-away');
                setAnimationWorkMobile('fade-away');
                await new Promise(resolve => setTimeout(resolve, (400)));
                setAnimationHouse2('fade-away-2');
                await new Promise(resolve => setTimeout(resolve, (1000)));
                window.scrollTo(0, 0);
                await new Promise(resolve => setTimeout(resolve, (200)));
                navigate('/project-id-4');
            break;
            case 3:
                setAnimationHome('fade-away');
                setAnimationHomeMobile('fade-away');
                setHouseCardStatus('house-card-data-no-hover');
                setAnimationTimeline('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationImg1('fade-away');
                setAnimationHouse2('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationP1('fade-away');
                setAnimationHouse1('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationLearnMore('fade-away');
                setAnimationHouse4('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationAboutUs('fade-away');
                setAnimationHouse6('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationP2('fade-away');
                setAnimationHouse5('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationMeetTheTeam('fade-away');
                setAnimationContactUs('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationOurSelectedWork('fade-away');
                setAnimationHouse7('fade-away');
                setAnimationWorkMobile('fade-away');
                await new Promise(resolve => setTimeout(resolve, (400)));
                setAnimationHouse3('fade-away-2');
                await new Promise(resolve => setTimeout(resolve, (1000)));
                window.scrollTo(0, 0);
                await new Promise(resolve => setTimeout(resolve, (200)));
                navigate('/project-id-6');
            break;
            case 4:
                setAnimationHome('fade-away');
                setAnimationHomeMobile('fade-away');
                setHouseCardStatus('house-card-data-no-hover');
                setAnimationTimeline('fade-away');
                setAnimationContactUsContent('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationP1('fade-away');
                setAnimationImg1('fade-away');
                setAnimationHouse2('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationAboutUs('fade-away');
                setAnimationContactUsMap('fade-away');
                setAnimationHouse1('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationLearnMore('fade-away');
                setAnimationHouse3('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationContactUsContainer('fade-away');
                setAnimationHouse6('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationP2('fade-away');
                setAnimationHouse5('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationMeetTheTeam('fade-away');
                setAnimationContactUs('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationOurSelectedWork('fade-away');
                setAnimationHouse7('fade-away');
                setAnimationWorkMobile('fade-away');
                await new Promise(resolve => setTimeout(resolve, (400)));
                setAnimationHouse4('fade-away-2');
                await new Promise(resolve => setTimeout(resolve, (1000)));
                window.scrollTo(0, 0);
                await new Promise(resolve => setTimeout(resolve, (200)));
                navigate('/project-id-7');
            break;
            case 5:
                setAnimationHome('fade-away');
                setAnimationHomeMobile('fade-away');
                setHouseCardStatus('house-card-data-no-hover');
                setAnimationTimeline('fade-away');
                setAnimationContactUsContent('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationP1('fade-away');
                setAnimationImg1('fade-away');
                setAnimationHouse2('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationAboutUs('fade-away');
                setAnimationContactUsMap('fade-away');
                setAnimationHouse1('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationLearnMore('fade-away');
                setAnimationHouse3('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationContactUs('fade-away');
                setAnimationHouse6('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationP2('fade-away');
                setAnimationHouse4('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationMeetTheTeam('fade-away');
                setAnimationContactUsContainer('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationOurSelectedWork('fade-away');
                setAnimationHouse7('fade-away');
                setAnimationWorkMobile('fade-away');
                await new Promise(resolve => setTimeout(resolve, (400)));
                setAnimationHouse5('fade-away-2');
                await new Promise(resolve => setTimeout(resolve, (1000)));
                window.scrollTo(0, 0);
                await new Promise(resolve => setTimeout(resolve, (200)));
                navigate('/project-id-9');
            break;
            case 6:
                setAnimationHome('fade-away');
                setAnimationHomeMobile('fade-away');
                setHouseCardStatus('house-card-data-no-hover');
                setAnimationTimeline('fade-away');
                setAnimationContactUsContent('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationP1('fade-away');
                setAnimationFooterLogo('fade-away');
                setAnimationHouse2('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationAboutUs('fade-away');
                setAnimationContactUsMap('fade-away');
                setAnimationHouse1('fade-away');
                setAnimationFooterTermsAndConditions('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationFooterAboutUs('fade-away');
                setAnimationHouse7('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationFooterHome('fade-away');
                setAnimationContactUs('fade-away');
                setAnimationHouse5('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationFooterContactInfo('fade-away');
                setAnimationHouse4('fade-away');
                setAnimationFooterHome('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationFooterMeetTheTeam('fade-away');
                setAnimationContactUsContainer('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationFooterContainer('fade-away');
                setAnimationFooterAllRR('fade-away');
                setAnimationHouse3('fade-away');
                await new Promise(resolve => setTimeout(resolve, (400)));
                setAnimationHouse6('fade-away-2');
                setAnimationWorkMobile('fade-away');
                await new Promise(resolve => setTimeout(resolve, (1000)));
                window.scrollTo(0, 0);
                await new Promise(resolve => setTimeout(resolve, (200)));
                navigate('/project-id-10');
            break;
            case 7:
                setAnimationHome('fade-away');
                setAnimationHomeMobile('fade-away');
                setHouseCardStatus('house-card-data-no-hover');
                setAnimationTimeline('fade-away');
                setAnimationContactUsContent('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationP1('fade-away');
                setAnimationImg1('fade-away');
                setAnimationFooterLogo('fade-away');
                setAnimationHouse2('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationAboutUs('fade-away');
                setAnimationLearnMore('fade-away');
                setAnimationContactUsMap('fade-away');
                setAnimationHouse1('fade-away');
                setAnimationFooterTermsAndConditions('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationP2('fade-away');
                setAnimationFooterAboutUs('fade-away');
                setAnimationHouse6('fade-away');
                setAnimationFooterAllRR('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationMeetTheTeam('fade-away');
                setAnimationFooterHome('fade-away');
                setAnimationContactUs('fade-away');
                setAnimationHouse5('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationFooterContactInfo('fade-away');
                setAnimationHouse4('fade-away');
                setAnimationFooterHome('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationFooterMeetTheTeam('fade-away');
                setAnimationContactUsContainer('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationHouse3('fade-away');
                setAnimationFooterContainer('fade-away');
                await new Promise(resolve => setTimeout(resolve, (400)));
                setAnimationHouse7('fade-away-2');
                setAnimationWorkMobile('fade-away');
                await new Promise(resolve => setTimeout(resolve, (1000)));
                window.scrollTo(0, 0);
                await new Promise(resolve => setTimeout(resolve, (200)));
                navigate('/project-id-11');
            break;
            case 'aboutUs':
                setHouseCardStatus('house-card-data-no-hover');
                setAnimationTimeline('fade-away');
                setAnimationContactUsContent('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationFooterLogo('fade-away');
                setAnimationHouse1('fade-away');
                setAnimationHouse2('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationContactUsMap('fade-away');
                setAnimationContactUs('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationFooterHome('fade-away');
                setAnimationHouse6('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationHouse7('fade-away');
                setAnimationHouse5('fade-away');
                setAnimationFooterTermsAndConditions('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationFooterContactInfo('fade-away');
                setAnimationHouse4('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationFooterMeetTheTeam('fade-away');
                setAnimationContactUsContainer('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationFooterAllRR('fade-away');
                setAnimationHouse3('fade-away');
                setAnimationWorkMobile('fade-away');
                await new Promise(resolve => setTimeout(resolve, (400)));
                setAnimationFooterAboutUs('fade-away-2');
                setAnimationFooterContainer('move-away-2b');
                await new Promise(resolve => setTimeout(resolve, (1000)));
                window.scrollTo(0, 0);
                await new Promise(resolve => setTimeout(resolve, (200)));
                navigate('/about-us');
            break;
            case 'meetTheTeamFooter':
                setHouseCardStatus('house-card-data-no-hover');
                setAnimationTimeline('fade-away');
                setAnimationContactUsContent('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationFooterLogo('fade-away');
                setAnimationHouse1('fade-away');
                setAnimationHouse2('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationContactUsMap('fade-away');
                setAnimationContactUs('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationFooterHome('fade-away');
                setAnimationHouse6('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationHouse7('fade-away');
                setAnimationHouse5('fade-away');
                setAnimationFooterTermsAndConditions('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationFooterContactInfo('fade-away');
                setAnimationHouse4('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationFooterAboutUs('fade-away');
                setAnimationContactUsContainer('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationFooterAllRR('fade-away');
                setAnimationHouse3('fade-away');
                setAnimationWorkMobile('fade-away');
                await new Promise(resolve => setTimeout(resolve, (400)));
                setAnimationFooterMeetTheTeam('fade-away-2');
                setAnimationFooterContainer('move-away-2b');
                await new Promise(resolve => setTimeout(resolve, (1000)));
                window.scrollTo(0, 0);
                await new Promise(resolve => setTimeout(resolve, (200)));
                navigate('/team');
            break;
            case 'termsAndConditions':
                setHouseCardStatus('house-card-data-no-hover');
                setAnimationTimeline('fade-away');
                setAnimationContactUsContent('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationFooterLogo('fade-away');
                setAnimationHouse1('fade-away');
                setAnimationHouse2('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationContactUsMap('fade-away');
                setAnimationContactUs('fade-away');
                setAnimationFooterMeetTheTeam('fade-away-2');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationFooterHome('fade-away');
                setAnimationHouse6('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationHouse7('fade-away');
                setAnimationHouse5('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationFooterContactInfo('fade-away');
                setAnimationHouse4('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationFooterAboutUs('fade-away');
                setAnimationContactUsContainer('fade-away');
                await new Promise(resolve => setTimeout(resolve, (80)));
                setAnimationFooterAllRR('fade-away');
                setAnimationHouse3('fade-away');
                setAnimationWorkMobile('fade-away');
                await new Promise(resolve => setTimeout(resolve, (400)));
                setAnimationFooterTermsAndConditions('fade-away');
                setAnimationFooterContainer('move-away-2b');
                await new Promise(resolve => setTimeout(resolve, (1000)));
                window.scrollTo(0, 0);
                await new Promise(resolve => setTimeout(resolve, (200)));
                navigate('/terms-and-conditions');
            break;
        }
    }

    const renderCard = (id) => {
        return (
            <div className={`${houseCardStatus} house-card-data w-100 h-100 d-flex align-items-center justify-content-center`}>
                <button className="d-flex align-items-center" onClick={() => clickedLink(id)}>
                    <p>Gallery</p>
                    <i className="bi bi-image"></i>
                </button>
            </div>
        );
    }

    return (
        <div className="d-flex flex-column width-100vw">
            <Parallax ref={refParallax1} pages={calculatePages()} id="parallax-1-home">
                <ParallaxLayer speed={0.01} id="home" className={`${animationHome} height-100vh w-100 cursor-click`} onClick={clickedHome}>
                    <div id="glass-banner-home" className="d-flex flex-column align-items-center justify-content-between glass-30-bg h-100 w-100">
                        <div></div>
                        <div className="d-flex flex-column align-items-center justify-content-center">
                            <h1 id="title-siltstone" className="c-10">Siltstone</h1>
                            <h1 id="title-design-group" className="black-1">DESIGN GROUP</h1>
                        </div>
                        <div>
                            <img id="arrow-down" className="h-100" src={arrowDown} alt=" " />
                        </div>
                    </div>
                </ParallaxLayer>
                <ParallaxLayer offset={0.999} factor={10} speed={0.4} id="parallax-2" className="d-flex flex-column align-items-center white-2-bg">
                    <div className="section-margin"></div>
                    <div id="about-us" className="section d-flex">
                        <div id="img-about-us-container" className="h-100">
                            <div id="img-about-us" className={`${animationImg1} h-100 w-100`}></div>
                        </div>
                        <div id="about-us-content" className="d-flex flex-column justify-content-center">
                            <h2 className={`c-8 ${animationAboutUs} m-0 w-100`}>About us</h2>
                            <p id="about-us-p-1" className={`black-1 ${animationP1} w-100`}>
                                We are a skillful team of four professionals that, since 2015, has dedicated to
                                creating innovative and sustainable architectural solutions.
                            </p>
                            <p id="about-us-p-2" className={`black-1 ${animationP2} w-100`}>
                                At Siltstone, we believe that architecture is more than just designing buildings;
                                it's about shaping environments and enriching lives.
                            </p>
                            <div className="d-flex flex-column align-items-start">
                                <button className={`d-flex align-items-center c-8 ${animationLearnMore}`} onClick={() => clickedLink('learnMore')}>
                                    <h4>Learn more</h4>
                                    <h4 id="learn-more-plus">+</h4>
                                </button>
                                <button id="meet-the-team-button" className={`c-8 ${animationMeetTheTeam}`} onClick={() => clickedLink('meetTheTeam')}>
                                    <h4>Meet the team</h4>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div id="our-work" className={`${animationTimeline} section `}>
                        <h2 id="our-work-title" className={`c-8 ${animationOurSelectedWork}`}>Our selected work</h2>
                    </div>
                    <div className="section-margin"></div>
                    <div id="contact" className="section d-flex flex-column">
                        <h2 className={`${animationContactUs} c-8`}>Contact us</h2>
                        <div id="map-container" className={`${animationContactUsContainer} d-flex c-1-bg`}>
                            <iframe id="gmap" className={`${animationContactUsMap}`}src="https://maps.google.com/maps?q=608+16th+St%2C+Oakland%2C+CA+94612%2C+Estados+Unidos&t=&z=13&ie=UTF8&iwloc=&output=embed"></iframe>
                            <div className={`${animationContactUsContent} d-flex flex-column justify-content-end h-100`}>
                                <h3 id="contact-adress">608 16th St, Oakland, CA 94612, USA</h3>
                                <h3>510-555-0198</h3>
                                <h3>contact@siltstone.com</h3>
                            </div>
                        </div>
                    </div>
                    <div className="section-margin-2"></div>
                    <div id="footer" className={`${animationFooterContainer} w-100 c-8-bg height-100vh d-flex flex-column align-items-center`}>
                        <div className="section-margin-footer w-100 c-6-bg"></div>
                        <div id="footer-content" className="d-flex align-items-start w-100">
                            <div className="footer-section d-flex align-items-center justify-content-center">
                                <img id="logo-img-footer" className={animationFooterLogo} src={logoWhite} alt="logo" />
                            </div>
                            <div className="footer-section d-flex justify-content-center">
                                <div className="d-flex flex-column">
                                    <button className={`${animationFooterHome} white-1`}>Home</button>
                                    <button className={`${animationFooterAboutUs} white-1`} onClick={() => clickedLink('aboutUs')}>About us</button>
                                    <button className={`${animationFooterMeetTheTeam} white-1`} onClick={() => clickedLink('meetTheTeamFooter')}>Meet the team</button>
                                    <button className={`${animationFooterTermsAndConditions} white-1`} onClick={() => clickedLink('termsAndConditions')}>Terms & Conditions</button>
                                </div>
                            </div>
                            <div className="footer-section-2 d-flex justify-content-center">
                                <div className={`${animationFooterContactInfo} d-flex flex-column justify-content-top white-1`}>
                                    <h3 id="contact-adress">608 16th St, Oakland, CA 94612, USA</h3>
                                    <h3>510-555-0198</h3>
                                    <h3>contact@siltstone.com</h3>
                                </div>
                            </div>
                        </div>
                        <h5 id="all-r-reserved" className={`${animationFooterAllRR} c-5 footer-bottom`}>All rights reserved | Siltstone Design Group</h5>
                        <h5 className={`${animationFooterAllRR} c-5 footer-bottom-2`}>Made by Ivo Gonsé</h5>
                    </div>
                </ParallaxLayer>
                <ParallaxLayer offset={0.95} factor={0} speed={0.55} className="d-flex flex-column align-items-center">
                    <div id="our-work-cards" className="section d-flex flex-column cursor-crosshair">
                        <div id="house-1" className={`house-card ${animationHouse1}`}>
                            {renderCard(1)}
                        </div>
                        <div id="house-2" className={`house-card ${animationHouse2}`}>
                            {renderCard(2)}
                        </div>
                        <div id="house-3" className={`house-card ${animationHouse3}`}>
                            {renderCard(3)}
                        </div>
                        <div id="house-4" className={`house-card ${animationHouse4}`}>
                            {renderCard(4)}
                        </div>
                        <div id="house-5" className={`house-card ${animationHouse5}`}>
                            {renderCard(5)}
                        </div>
                        <div id="house-6" className={`house-card ${animationHouse6}`}>
                            {renderCard(6)}
                        </div>
                        <div id="house-7" className={`house-card ${animationHouse7}`}>
                            {renderCard(7)}
                        </div>
                    </div>
                </ParallaxLayer>
            </Parallax>
            <div id="mobile-version" className={`${animationMobile} flex-column align-items-center`}>
                <div className={`${animationHomeMobile} w-100`}>
                    <div id="home" className={`${animationHomeMobile} w-100`}>
                        <div id="glass-banner-home" className="d-flex flex-column align-items-center justify-content-between glass-30-bg h-100 w-100">
                            <div></div>
                            <div className="d-flex flex-column align-items-center justify-content-center">
                                <h1 id="title-siltstone" className="c-10">Siltstone</h1>
                                <h1 id="title-design-group" className="black-1">DESIGN GROUP</h1>
                            </div>
                            <div>
                                <img id="arrow-down" className="h-100" src={arrowDown} alt=" " />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-margin"></div>
                <div id="about-us" className="section d-flex">
                    <div id="about-us-content" className="d-flex flex-column align-items-center justify-content-center">
                        <h2 className={`c-8 ${animationAboutUs}`}>About us</h2>
                        <p id="about-us-p-1" className={`black-1 ${animationP1} px-0 w-100`}>
                            We are a skillful team of four professionals that, since 2015, has dedicated to
                            creating innovative and sustainable architectural solutions.
                        </p>
                        <p id="about-us-p-2" className={`black-1 ${animationP2} px-0 w-100`}>
                            At Siltstone, we believe that architecture is more than just designing buildings;
                            it's about shaping environments and enriching lives.
                        </p>
                        <div id="mobile-about-us-buttons" className="d-flex align-items-center justify-content-center">
                            <button className={`d-flex align-items-center c-8 ${animationLearnMore} me-3`} onClick={() => clickedLink('learnMore')}>
                                <h4>Learn more</h4>
                                <h4 id="learn-more-plus">+</h4>
                            </button>
                            <button id="meet-the-team-button" className={`c-8 ${animationMeetTheTeam} my-0 ms-3`} onClick={() => clickedLink('meetTheTeam')}>
                                <h4>Meet the team</h4>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="section-margin"></div>
                <div id="mobile-selected-work" className={`${animationWorkMobile} w-100 c-1-bg d-flex flex-column align-items-center`}>
                    <h2 id="mobile-selected-work-title" className={`c-8 ${animationOurSelectedWork} text-center`}>Our selected work</h2>
                    <div className="project-row d-flex align-items-center justify-content-center">
                        <button className={`mobile-house-img ${animationHouse1}`} id="mobile-house-1" onClick={() => clickedLink(1)}></button>
                        <button className={`mobile-house-img ${animationHouse2}`} id="mobile-house-2" onClick={() => clickedLink(2)}></button>
                    </div>
                    <div className="project-row d-flex align-items-center justify-content-center">
                        <button className={`mobile-house-img ${animationHouse3}`} id="mobile-house-3" onClick={() => clickedLink(3)}></button>
                        <button className={`mobile-house-img ${animationHouse4}`} id="mobile-house-4" onClick={() => clickedLink(4)}></button>
                    </div>
                    <div className="project-row d-flex align-items-center justify-content-center">
                        <button className={`mobile-house-img ${animationHouse5}`} id="mobile-house-5" onClick={() => clickedLink(7)}></button>
                        <button className={`mobile-house-img ${animationHouse6}`} id="mobile-house-6" onClick={() => clickedLink(6)}></button>
                    </div>
                </div>
                <div className="section-margin"></div>
                <div className="section d-flex flex-column align-items-center">
                    <h2 className={`${animationContactUs} c-8`}>Contact us</h2>
                    <img id="map-img" className={animationContactUsMap} src={mapImg} alt="map" />
                    <div className={`${animationContactUsContent} d-flex flex-column justify-content-end h-100`}>
                        <p id="contact-adress" className="mb-0">608 16th St, Oakland, CA 94612, USA</p>
                        <p className="mb-0">510-555-0198</p>
                        <p className="mb-1">contact@siltstone.com</p>
                    </div>
                </div>
                <div className="section-margin"></div>
                <div id="footer" className={`${animationFooterContainer} w-100 c-8-bg d-flex flex-column align-items-center`}>
                    <div className="section-margin-footer w-100 c-6-bg"></div>
                    <div id="footer-content" className="d-flex align-items-start justify-content-center w-100">
                        <div className="footer-section d-flex align-items-center justify-content-center">
                            <img id="logo-img-footer" className={animationFooterLogo} src={logoWhite} alt="logo" />
                        </div>
                        <div className="footer-section d-flex justify-content-center">
                            <div className="d-flex flex-column">
                                <button className={`${animationFooterHome} white-1`}>Home</button>
                                <button className={`${animationFooterAboutUs} white-1`} onClick={() => clickedLink('aboutUs')}>About us</button>
                                <button id="team-button" className={`${animationFooterMeetTheTeam} white-1`} onClick={() => clickedLink('meetTheTeamFooter')}>Meet the team</button>
                                <button id="terms-button" className={`${animationFooterTermsAndConditions} white-1`} onClick={() => clickedLink('termsAndConditions')}>Terms & Conditions</button>
                            </div>
                        </div>
                    </div>
                    <h5 id="all-r-reserved" className={`${animationFooterAllRR} c-5 footer-bottom`}>All rights reserved | Siltstone Design Group</h5>
                    <h5 className={`${animationFooterAllRR} c-5 footer-bottom-2`}>Made by Ivo Gonsé</h5>
                </div>
            </div>
        </div>
        );
}

export default MainMenu;